.contactMobile {
    background: url('../../Assets/contact-mobile.png') no-repeat;
    background-size: cover;
    padding-bottom: 3vw;
    color: black !important;

}

.contactDesktop {
    background: url('../../Assets/contact-desktop.png') no-repeat;
    background-size: cover;
    padding: 3vw 0 4vw 0;
}

.mobileCard {
    background-color: rgba(255, 255, 255, 0.695);
    width: 85%;
    padding: 2rem .8rem;
    margin: 0 auto;
}

.formStyle {
    margin: -4vw 2vw 0 4vw;
}

/* purple heart img */
.contactTitle::before {
    display: block;
    width: 100%;
    height: 45vw;
    content: " ";
    background-image: url("../../Assets/purple-heart-mobile.png");
    background-size: 98%;
    margin: -35px 0 0 0;
}

.contactTitle {
    font-family: var(--title-about);
    text-align: center;
    margin: 1rem 0 !important;
    font-size: 18vw;
}

.infoText,
.infoTextB {
    font-family: var(--body-primary);
    font-size: 4vw;
    text-align: left;
}

.formField {
    margin: 0 0 1rem 0;
    background-color: black;
    border-radius: 0;
}

.formField::placeholder {
    color: white;
}

.btnDiv {
    display: flex;
}

.btn {
    font-family: var(--body-primary);
    width: 50%;
    border-radius: .5rem;
    border: none;
    color: white;
    background-color: black;
    padding: .5rem .5rem;
    font-size: 4.2vw;
    line-height: 1.3rem;
    margin: 5px auto 0 auto;
}

.btn:hover {
    color: var(--gold);
    background-color: var(--light-grey);
    transform: scale(.98);
}

@media screen and (min-width:414px) {
    .btn {
        font-size: 3.8vw;
    }

    .contactMobile {
        padding-bottom: 4vw;
    }

    .labelForm {
        font-size: 4vw;
    }
}

@media screen and (min-width:568px) {
    .contactMobile {
        padding-bottom: 0vw;
    }

    .contactTitle {
        margin: 8vw 0 2vw 0;
    }

    .infoText {
        font-size: 3vw;
    }

    .labelForm {
        font-size: 3vw;
    }

    .btn {
        font-size: 2.8vw;
    }
}

@media screen and (min-width:667px) {
    .infoText {
        font-size: 2.5vw;
    }

    .labelForm {
        font-size: 2.5vw;
    }

    .btn {
        font-size: 2.5vw;
    }
}

@media screen and (min-width:726px) {

    /* purple heart img */
    .contactTitle::before {
        display: block;
        width: 39%;
        height: 11vw;
        content: " ";
        background-image: url("../../Assets/purple-heart-tablet.png");
        background-repeat: no-repeat;
        background-size: 98%;
        margin: -15px auto 0 auto;
    }


    .cardBackground {
        background-color: rgba(0, 0, 0, 0.75);
        border-radius: 1vw;
        margin: 0vw 3vw 0vw 3vw
    }

    .formStyle {
        margin: 1vw 2vw 0 4vw;
    }

    .contactTitle {
        padding: 0 0 0 42px;
        font-size: 12vw;
        line-height: 74px;
        display: flex;
        width: 74%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }

    .contactDesktop {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .infoText,
    .infoTextB {
        font-size: 1.8vw;
        color: white;
    }

    .infoText {

        color: white;
    }

    .rowB,
    .rowC {
        margin-bottom: 10px;
        gap: 10px;
    }

    .cardBody {
        padding: 1vw;
    }

    .btnDiv {
        display: block;
    }

    .btn {
        color: var(--gold);
        font-size: 2vw;
        width: 30%;
        background-color: white;
    }
}

@media screen and (min-width:1024px) {


    .contactTitle::before {
        width: 41%;
        height: 15vw;
    }

    .infoText,
    .infoTextB {
        font-size: 19px;
    }

    .btn {
        font-size: 16px;
        width: 13%;
    }

    .contactTitle {
        font-size: 9vw;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        width: 93%;
        padding: 0 0 0 241px;
    }

}

@media screen and (min-width:1366px) {

    .contactTitle::before {
        /* height: 265px; */
    }

    .contactTitle {
        font-size: 155px;
    }

    .infoText,
    .infoTextB {
        font-size: 19px;
    }


    .btn {
        font-size: 16px;
        width: 13%;
        margin: 0;
    }

    .btnDiv {
        margin: 22px 0;
    }

    .cardBody {
        padding: 1px;
    }

    .cardBackground {
        margin: 2px 50px;
    }

    .formStyle {
        margin: 44px
    }

    .contactDesktop {
        padding: 33px
    }
}

@media screen and (min-width:1730px) {
    .contactTitle::before {
        height: 265px;
    }
}