.headerWrapper {
    background: url("../../Assets/header-mobile.png") no-repeat;
    background-size: contain;
    /* overflow: hidden; */
    position: relative;
    z-index: 5;
    /*  top: 2px; */
}

.logoImg {
    width: 10%;
    height: auto;
    margin: 5vw 0 3vw 2vw;
}

.fullLogoStyle {
    width: 85%;
    /* margin-top: 100px; */
    height: auto;
    border-bottom: 4px solid #41294c;
}


.titleWrapper {
    margin: 0 0 0 8vw;
    display: flex;
    width: 92%;
    margin: 0 auto -100px auto;

}

.titleWrapper div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.name {
    font-size: 6.5vw;
    /* font-family: var(--title-primary); */
    text-align: center;
    color: black;
    line-height: 9vw;
    margin-bottom: 1rem;
}

.title {
    font-size: 3.7vw;
    font-family: var(--title-primary);
    color: black;
    line-height: 3.7vw;
    text-align: justify;
    width: 95%;
    font-weight: normal;
}

.btn {
    position: relative;
    z-index: 12;
    font-family: var(--body-primary);
    width: fit-content;
    border-radius: .5rem;
    border: none;
    color: white;
    background-color: black;
    font-size: 5vw;
    padding: 2.5vw 5vw;
    line-height: 1.3rem;
    margin-top: -10px;
    margin-left: -2vw;
}

.btn:hover {
    color: var(--gold);
    background-color: var(--light-grey);
    transform: scale(.98);
}

.vaseFlowers {
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;
    top: -45px;
}


@media screen and (min-width:500px) {
    .vaseFlowers {
        /* math css to removed 20% from margin based on vw */
        margin-top: -12.2vw;
    }

    .headerWrapper {
        background: url("../../Assets/header-mobile-bigger.png") no-repeat;
        background-size: contain;
        overflow: hidden;
        /* position: absolute; */
    }

}

@media screen and (min-width:640px) {
    .btn {
        font-size: 4vw;
    }
}


@media screen and (min-width:726px) {

    /* black nav-line */
    /* .headerWrapper::before {
        content: "";
        background: black;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4vw;
        display: none;
    } */

    .headerWrapper {
        background: url("../../Assets/header-desktop-bigger-1.jpg") no-repeat;
        background-size: cover;
        height: 60vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 0;
    }

    /* vase-flowers-img */
    .headerWrapper::after {
        content: "";
        background: url("../../Assets/case-flowers.png") no-repeat;
        background-size: contain;
        position: absolute;
        /* bottom: -86px; */
        right: -163px;
        width: 377px;
        height: 475px;
        display: block;
    }

    .titleWrapper {
        margin: -130px auto 0px auto;
    }

    .logoImg {
        margin: 0 0 3vw 2vw;
    }

    .fullLogoStyle {
        width: 82%;
        height: auto;
        border-bottom: 4px solid #41294c;
        /* margin-top: 3vw; */
    }

    .name {
        font-size: 4.5vw;
        margin: 1vw 0 -.1vw 0;
        line-height: 6vw;
        width: 70%;
    }

    .title {
        font-size: 1.9vw;
        line-height: 2vw;
        width: 81%;
        background: #eae8e6ba;
        padding: .5rem 1rem;
        border-radius: .5rem;
        text-align: center;
        position: relative;
        z-index: 2;
        margin-bottom: 0;
        margin-top: 1rem;
    }

    .btnWrapper {
        display: flex;
        justify-content: flex-start;
        margin-left: -1vw;
        margin-top: -21px;
    }

    .btn {
        margin-top: 10px;
        font-size: 2vw;
        padding: 1.5vw 3.5vw;
    }

    .vaseFlowers {
        display: none;
    }

}

@media screen and (min-width:800px) {
    .headerWrapper::after {
        /* bottom: -106px; */
        right: -103px;
        width: 377px;
        height: 605px;
    }

}

@media screen and (min-width:950px) {

    .headerWrapper::after {
        /* bottom: -125px; */
        right: -66px;
        width: 377px;
        height: 686px;
    }

}

@media screen and (min-width:995px) {

    /* black nav-line */
    .headerWrapper::before {
        display: block;
    }


    .headerWrapper::after {
        background-image: url("../../Assets/case-flowers-1.png");
    }

    .btn {
        padding: 10px 50px;
    }



}

@media screen and (min-width:1200px) {

    .headerWrapper::after {
        /* bottom: -155px; */
        right: -35px;
        width: 467px;
        height: 957px;
    }

    .headerWrapper::before {
        height: 44px;
    }

    .headerWrapper {
        height: 810px;
    }

}



@media screen and (min-width:1440px) {

    .headerWrapper {
        background: url("../../Assets/header-desktop-bigger.jpg") no-repeat;
        background-size: cover;
    }

    .headerWrapper::before {
        display: none;
    }

    .btn {
        font-size: 26px;
        padding: 11.5px 26.5px;
    }

    .title {
        font-size: 21.5px;
        line-height: 27.7px;
    }

}


@media screen and (min-width:1500px) {

    .headerWrapper::after {
        width: 467px;
        height: 957px;
    }

    .headerWrapper {
        height: 880px;
    }
}


@media screen and (min-width:1700px) {
    .name {
        font-size: 60px;
        line-height: 89px;
    }

}

@media screen and (min-width:1920px) {
    .headerWrapper {
        height: 990px;
    }

}

@media screen and (min-width:2560px) {
    .headerWrapper {
        height: 1190px;
    }

}