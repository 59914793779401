.footerStyle {
    background-color: black;
    color: var(--white);
    /* font-family: var(--body-secondary); */
    font-size: 2.5vw;
    text-align: center;
    padding: .5rem;
}

@media screen and (min-width:568px) {
    .footerStyle {
        font-size: 2.5vw;
    }
}

@media screen and (min-width:667px) {
    .footerStyle {
        font-size: 2vw;
    }
}

@media screen and (min-width:726px) {
    .footerStyle {
        font-size: 1.5vw;
    }
}

@media screen and (min-width:1024px) {
    .footerStyle {
        font-size: 1.2vw;
    }

}

@media screen and (min-width:1366px) {
    .footerStyle {
        font-size: 1.1vw;
    }

}

@media screen and (min-width:1700px) {
    .footerStyle {
        font-size: 15px;
    }
}