.navBar {
   background: linear-gradient(180deg, rgb(45 45 45), rgb(0 0 0)) !important;
}

button.navbar-toggler.collapsed {
   border: none;
   margin-top: 3px;
}

.expandedNavBar {
   background: linear-gradient(180deg, rgb(45 45 45), rgb(0 0 0)) !important;

   /* Change to desired color */
}

.navBarBrand {
   color: white !important;
}

/* .containerNav {
   display: flex;
   flex-direction: row-reverse;
} */

a:hover {
   font-weight: 550 !important;
}

/* Ensure nav links are not white */
.nav-link {
   color: black !important;
   /* Change to desired color */
}

.nav-link:hover {
   color: var(--hover-color) !important;
   /* Change to desired hover color */
}

/* Class to push the main content down when navbar is expanded */
.mainContent {
   transition: margin-top 0.3s ease;

   /* Adjust based on the height of your navbar */
}

/* .mainContentExpanded {
   margin-top: 200px;

} */

@media screen and (min-width:726px) {
   .navBar {
      background: linear-gradient(180deg, rgb(165 70 55), rgb(133 29 16)) !important;
   }

   .expandedNavBar {
      background: linear-gradient(180deg, rgb(163 30 81 / 83%), rgb(132 27 31)) !important;

      /* Change to desired color */
   }

}