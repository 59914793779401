.headerWrapper {
    background: url("../../Assets/header-mobile.png") no-repeat;
    background-size: contain;
    /* overflow: hidden; */
    position: relative;
    z-index: 5;
    /*  top: 2px; */
}

.logoImg {
    width: 10%;
    height: auto;
    margin: 5vw 0 3vw 2vw;
}

.fullLogoStyle {
    width: 85%;
    height: auto;
    border-bottom: 4px solid #41294c;
}


.titleWrapper {
    margin: 0 0 0 8vw;
    display: flex;
    width: 92%;
    margin: 0 auto;

}

.titleWrapper div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.name {
    font-size: 5vw;
    font-family: var(--title-primary);
    color: black;
    line-height: 9vw;
    margin-bottom: 0;
}

.title {
    font-size: 2.8vw;
    font-family: var(--title-primary);
    color: black;
    line-height: 3.2vw;
    width: 95%;
    font-weight: normal;
}

.btn {
    position: relative;
    z-index: 12;
    font-family: var(--body-primary);
    width: 200%;
    border-radius: 1.5rem;
    border: none;
    color: white;
    background-color: black;
    padding: .5rem .5rem;
    font-size: 4.2vw;
    line-height: 1.3rem;
    margin-top: -10px;
}

.btn:hover {
    color: var(--gold);
    background-color: var(--light-grey);
    transform: scale(.98);
}

.vaseFlowers {
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;
    top: -140px;
}


@media screen and (min-width:500px) {
    .vaseFlowers {
        /* math css to removed 20% from margin based on vw */
        margin-top: -12.2vw;
    }

    .headerWrapper {
        background: url("../../Assets/header-mobile-bigger.png") no-repeat;
        background-size: contain;
        overflow: hidden;
        /* position: absolute; */
    }

}

@media screen and (min-width:726px) {

    /* black nav-line */
    .headerWrapper::before {
        content: "";
        background: black;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4vw;
        display: none;
    }

    .headerWrapper {
        background: url("../../Assets/header-desktop-bigger-1.jpg") no-repeat;
        background-size: cover;
        height: 60vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 0;
    }

    /* vase-flowers-img */
    .headerWrapper::after {
        content: "";
        background: url("../../Assets/case-flowers.png") no-repeat;
        background-size: contain;
        position: absolute;
        /* bottom: -86px; */
        right: -163px;
        width: 377px;
        height: 475px;
        display: block;
    }

    .titleWrapper {
        margin: -130px auto 0px auto;
    }

    .logoImg {
        margin: 0 0 3vw 2vw;
    }

    .fullLogoStyle {
        width: 82%;
        height: auto;
        border-bottom: 4px solid #41294c;
        /* margin-top: 3vw; */
    }

    .name {
        font-size: 2.5vw;
        margin: 1vw 0 -.1vw 0;
        line-height: 3.5vw;
    }

    .title {
        font-size: 1.28vw;
        line-height: 1.8vw;
        width: 61%;
    }

    .btnWrapper {
        display: flex;
        justify-content: flex-start;
        margin-left: -1vw;
    }

    .btn {
        width: 220%;
        padding: .5vw;
        /* margin: 1vw 0 0 6vw; */
        font-size: 2vw;

    }

    .vaseFlowers {
        display: none;
    }

}

@media screen and (min-width:800px) {
    .headerWrapper::after {
        /* bottom: -106px; */
        right: -103px;
        width: 377px;
        height: 605px;
    }

}

@media screen and (min-width:950px) {

    .headerWrapper::after {
        /* bottom: -125px; */
        right: -66px;
        width: 377px;
        height: 686px;
    }

}

@media screen and (min-width:995px) {

    /* black nav-line */
    .headerWrapper::before {
        display: block;
    }


    .headerWrapper::after {
        background-image: url("../../Assets/case-flowers-1.png");
    }

}

@media screen and (min-width:1200px) {

    .headerWrapper::after {
        /* bottom: -155px; */
        right: -35px;
        width: 467px;
        height: 957px;
    }

    .headerWrapper::before {
        height: 44px;
    }

    .headerWrapper {
        height: 810px;
    }

}


@media screen and (min-width:1440px) {

    .headerWrapper {
        background: url("../../Assets/header-desktop-bigger.jpg") no-repeat;
        background-size: cover;
    }

    .headerWrapper::before {
        display: none;
    }


}


@media screen and (min-width:1500px) {

    .headerWrapper::after {
        width: 467px;
        height: 957px;
    }

    .headerWrapper {
        height: 880px;
    }
}


@media screen and (min-width:1700px) {
    .name {
        font-size: 36px;
    }



}