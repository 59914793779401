.navbar .navbar-nav .nav-link {
    color: inherit;
    /* Ensure the text color is inherited or set to a desired color */
}

.navbar .navbar-brand {
    color: inherit;
    /* Ensure the brand color is inherited or set to a desired color */
}

.navbar-toggler-icon {
    filter: invert(1) !important;
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: transparent;
    border-color: transparent;
}

.accordion-item {
    background-color: transparent;
    border: none;
}

.accordion {
    --bs-accordion-active-bg: transparent
}

.accordion-button:not(.collapsed) {
    padding: 0;
}


.sq-card-wrapper .sq-card-message {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.c-jWYnUm {
    background-color: rgb(191 145 8) !important;
    border: none;
}

/* .input-group>.form-control, .input-group>.form-floating, .input-group>.form-select {
    margin: 2px;
} */


/* SWIPER TESTIMONY */
.navBar {
    background-color: #b8ab4b;
}

.swiper-pagination {
    position: static;
}

.swiper,
.swiper-port {
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

.swiper-pagination-bullet {
    background-color: #a21f24;
    width: 0.5rem;
    height: 0.5rem;
    margin-left: 0.5rem;
}

.swiper-slide {
    display: flex;
    flex-wrap: wrap;
    /* padding: 2.2rem; */
    padding-top: 0;
    line-height: 1rem;
    text-align: center;
    font-size: 12px;
    color: rgb(46, 46, 46);
    height: 7rem;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.aside-icon {
    font-family: Arial, Helvetica, sans-serif;
    /* font-style: italic; */
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap-reverse;
    max-width: 58vw;
    font-size: 1rem;
    font-weight: 200 !important;
}

.swiper-button-prev {
    color: #a21f2470;
}

.swiper-button-next {
    color: #a21f24;
}

.circleBase {
    border-radius: 50%;
}

.circle {
    margin-top: 1rem;
    width: 100px;
    height: 100px;
    background: #4bc475;
    border: 1px solid #000;
}

.circle-img1,
.circle-img1-a,
.circle-img2,
.circle-img2-a,
.circle-img3,
.circle-img3-a,
.circle-img4,
.circle-img4-a,
.circle-img5,
.circle-img5-a,
.circle-img6,
.circle-img6-a {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100px;
    height: 100px;
    border: 1px solid #000;
}

.circle-img1,
.circle-img1-a {
    /* background: url("../../images/daniel.jpg"); */
    background-size: contain;
}

.circle-img2,
.circle-img2-a {
    /* background: url("../../images/mariam.jpg"); */
    background-size: contain;
}

.circle-img3,
.circle-img3-a {
    /* background: url("../../images/HeartSongLogo.png"); */
    background-size: cover;
    background-color: white;
}

.circle-img4,
.circle-img4-a {
    /* background: url("../../images/dright.jpg"); */
    background-size: cover;
    background-color: white;
}

/* 
.circle-img5,
.circle-img5-a {
    background: url("../../images/htaylor.jpg");
    background-size: cover;
    background-color: white;
}

.circle-img6,
.circle-img6-a {
    background: url("../../images/emile.jpg");
    background-size: cover;
    background-color: white;
} */

a {
    text-decoration: none;
    color: #e2e2e2;
}

a:hover {
    color: #162336;
}

a:active {
    color: #162336;
}

.material-symbols-outlined {
    color: #ffffff;
    font-size: 5rem;
}

.navbar-toggler-icon {
    width: 37.5px !important;
    height: 37px !important;
}

.circle-img1-a,
.circle-img2-a,
.circle-img3-a,
.circle-img4-a,
.circle-img5-a,
.circle-img6-a {
    display: none;
}

/* @media screen and (min-width: 280px) {

    .circle-img1,
    .circle-img2,
    .circle-img3,
    .circle-img4,
    .circle-img5,
    .circle-img6 {
        margin-top: 0.5rem;
    }
} */

@media screen and (min-width: 320px) {
    .aside-icon {

        font-size: 3.3vw;
        line-height: 3.4vw;
    }
}



@media screen and (min-width: 726px) {

    .circle-img1,
    .circle-img2,
    .circle-img3,
    .circle-img4,
    .circle-img5,
    .circle-img6 {
        display: none;
    }

    .circle-img1-a,
    .circle-img2-a,
    .circle-img3-a,
    .circle-img4-a,
    .circle-img5-a,
    .circle-img6-a {
        display: none;
        /* width: 150px;
        height: 150px;
        margin-right: 1rem; */
    }

    .aside-icon {
        font-size: 2.3vw;
        line-height: 1.2rem;
        max-width: 25rem;
        font-family: "Times New Roman", Times, serif;
        font-weight: 100;
        font-style: italic;
    }

}

@media screen and (min-width: 1024px) {

    .aside-icon {

        max-width: 43rem;
        font-family: "Times New Roman", Times, serif;
        font-weight: 100;
        font-style: italic;
    }

}


@media screen and (min-width: 1250px) {
    .btn-light {
        background-color: #bababaca !important;
        box-shadow: #f3f3f3e0 0px -50px 36px -29px inset;
        color: #574c41;
    }

    .aside-icon {
        font-size: 1.7rem;
        line-height: 1.8rem;
        max-width: 50rem;
        font-family: "Times New Roman", Times, serif;
        font-weight: 100;
        font-style: italic;
    }
}

@media screen and (min-width: 1500px) {


    .btn-light {
        background-color: #dcdcd4 !important;
    }
}


/* -------------------------------------------------------------------------------- */

@media (min-width: 768px) {
    .container, .container-md, .container-sm {
        max-width: 800px;
    }
}

@media screen and (min-width:992px) {
    .navbar {
        --bs-navbar-nav-link-padding-x: 0.42rem !important;
        width: auto;
    }
}