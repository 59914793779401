.aboutImg {
    width: 95%;
    margin: 54px 0 0 -7px;
}

.headerWrapper {
    background: url("../../Assets/about-mobile.jpg") no-repeat;
    background-size: cover;
    /* overflow: hidden; */
    /* position: absolute;
    z-index: -5;
    top: 664px; */
}

.storeImgStyle {
    width: 95%;
    margin: 14px 0 0 -7px;
}

.storeTitle {
    font-family: var(--title-about);
    color: white;
    font-size: 18vw;
    margin-top: -20px;
    text-shadow: rgba(88, 4, 4, 0.47) 1px 1px 10px;
}

.productsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.innerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 0 0;
}

.productImg {
    width: 90%;
    border-radius: .5rem;
    transition: transform 0.3s ease-in-out;
    /* Add this line */

}

.productImg:hover {
    transform: scale(1.05);
}

.productTitle {
    font-family: var(--sub-title-about);
    color: white;
    text-transform: uppercase;
    font-size: 5vw;
    margin-top: 10px;

}

.aboutSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #cf171f;
    background: linear-gradient(0deg, rgb(86, 3, 5) 0%, rgb(86, 3, 5) 20%, rgb(162, 31, 36) 45%, rgb(162, 31, 36) 87%, rgba(247, 9, 9, 0) 100%, rgba(247, 9, 9, 0) 100%, rgba(0, 212, 255, 1) 100%);
    padding: .7rem;
}

.asideStyle {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 7%, rgba(5, 4, 69, 0) 100%, rgba(9, 9, 121, 1) 100%, rgba(0, 212, 255, 1) 100%);

}


/* selects fist p tag in section */
.aboutSection h2>p:first-of-type {
    color: white;
}


.btn {
    font-family: var(--body-primary);
    border-radius: .2rem;
    border: none;
    color: black;
    background-color: white;
    padding: .3rem .5rem;
    font-size: 3.2vw;
    line-height: 1.3rem;
    margin-top: -5px;
}

.btn:hover {
    color: var(--gold);
    background-color: var(--light-grey);
    transform: scale(.98);
}

/* read more btn */
.btnReadMore {
    /* position: relative;
    z-index: 12; */
    font-family: var(--body-primary);
    width: fit-content;
    border-radius: .5rem;
    border: none;
    color: black;
    background-color: white;
    font-size: 3.2vw;
    line-height: 1.3rem;
    padding: 2.5vw 5vw;
    margin: -10px 0 1rem 0;
}

.btnReadMore:hover {
    color: var(--gold);
    background-color: var(--light-grey);
    transform: scale(.98);
}

.btnWrapper {
    width: 95%;
    display: flex;
    justify-content: left;
    margin-bottom: 50px;
}

.titleWrapper {
    margin: 0 0 0 8vw;
    display: flex;
    width: 92%;
    margin: 0 auto;

}

.titleWrapper div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 3.8vw;
    font-family: var(--title-primary);
    color: black;
    line-height: 4.2vw;
    width: 95%;
    font-weight: normal;
}


@media screen and (min-width:355px) {
    .headerWrapper {
        top: 860px;
    }
}

@media screen and (min-width:726px) {


    .headerWrapper {
        top: 550px;
    }

    .productsContainer {
        display: flex;
        flex-direction: row;
        gap: 3px;
        /* gap: 1rem; */
    }

    .productImg {
        width: 90%;
    }

    .productTitle {
        font-size: 12.5px;
        margin: 8px 0;

    }

    .title {
        font-size: 13.5px;
        line-height: 15.5px;
    }

    .btn, .btnReadMore {
        width: fit-content;
        padding: 2.5px 9px;
        font-size: 14px;
        margin-top: 6px;
    }

    .btnReadMore {
        padding: 10.5px 10px;
        font-size: 20px;
        margin: -10px 0 1rem 0;
    }

    /* makes four rows */
    /* width @33% makes three rows  */
    .innerContainer {
        width: 49%;
    }

    .storeTitle {
        font-size: 32px;
    }
}

@media screen and (min-width:1040px) {

    /* makes four rows */
    .innerContainer {
        width: 24%;
    }
}


@media screen and (min-width:1200px) {


    .aboutSection {
        background: #a21f24;
    }

    .asideStyle {
        padding-top: 40px;
        background: rgb(244 244 242);
    }
}


@media screen and (min-width:1321px) {

    .asideStyle {
        padding-top: 70px;
    }
}