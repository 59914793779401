.aboutImg {
    width: 71%;
    margin: 20px 0 0 45px;
}


.headerWrapper {
    background: url("../../Assets/about-mobile.jpg") no-repeat;
    background-size: cover;
    /* overflow: hidden; */
    /* position: absolute; */
    /* z-index: -5;
    top: -100px; */
}

.aboutSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0 0 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 7%, rgba(5, 4, 69, 0) 100%, rgba(9, 9, 121, 1) 100%, rgba(0, 212, 255, 1) 100%);
    border-radius: .5rem;
    padding: .7rem;
}

.missionWrapper {
    background-color: #ffffffe3;
    background: linear-gradient(180deg, rgb(245 245 244) 0%, rgb(248 247 247) 7%, rgba(5, 4, 69, 0) 100%, rgba(9, 9, 121, 1) 100%, rgba(0, 212, 255, 1) 100%);
    padding: .5rem;
    border-radius: .5rem;
    text-align: justify;
}

.asideStyle {
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 7%, rgba(5, 4, 69, 0) 100%, rgba(9, 9, 121, 1) 100%, rgba(0, 212, 255, 1) 100%);
    padding: 0 .7rem 3rem .7rem;
}

.name,
.about,
.testimoniesTitle {
    font-size: 10vw;
    font-family: var(--title-about);
    color: black;
    line-height: 17vw;
    margin: -30px 0 0 63px;
    letter-spacing: -2px;
}

.name::after {
    content: "OWNER/CREATOR";
    display: block;
    font-family: var(--sub-title-about);
    font-size: 11px;
    background-color: #a21f24;
    color: white;
    width: fit-content;
    margin: -18px 0 0 0;
    padding: 0 5px;
    line-height: 23px;
    letter-spacing: 0;
    border-radius: .2rem;
}

.about {
    font-size: 16vw;
    line-height: 14vw;
    margin: 0 0 0 -35px;
    padding: 10px 2.5rem;
}

.testimoniesTitle {
    margin: 1rem 0 0 0;
    /* padding-left: 3vw; */
    font-size: 10vw;

}

/* selects fist p tag in section */
.aboutSection h2>p:first-of-type {
    margin-top: 10px !important;
}

.aboutSection h2>p:nth-of-type(2) {
    margin-top: 10px !important;
}


.btn {
    position: relative;
    z-index: 12;
    font-family: var(--body-primary);
    border-radius: .5rem;
    border: none;
    color: white;
    background-color: black;
    font-size: 5vw;
    padding: 2.5vw 5vw;
    line-height: 1.3rem;
    margin-top: 10px;
}

.btn:hover {
    color: var(--gold);
    background-color: var(--light-grey);
    transform: scale(.98);
}

.btnWrapper {
    width: 95%;
    display: flex;
    justify-content: center;
}

.titleWrapper {
    margin: 0 0 0 8vw;
    display: flex;
    width: 92%;
    margin: 0 auto;

}

.titleWrapper div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 3.7vw;
    font-family: var(--title-primary);
    color: black;
    line-height: 4.1vw;
    width: 95%;
    font-weight: normal;
}

.hrStyle {
    display: block;
}

.swiperDiv {
    text-align: center;
    margin: 0 auto;
    padding: 0 0 20px 0;
}


@media screen and (min-width:500px) {


    .headerWrapper {
        margin-top: -70px;
    }

    .aboutSection h2>p:first-of-type {
        margin-top: 20px !important;
    }

    .title {
        width: 90%;
    }
}

@media screen and (min-width:640px) {
    .btn {
        font-size: 4vw;
    }
}

@media screen and (min-width:726px) {

    .swiperDiv {
        margin-left: 6rem;
    }

    .testimoniesTitle {
        /* padding-left: 8vw; */
        border-top: .5rem solid #a21f24;
        margin-top: 2rem;
    }

    .hrStyle {
        display: none;
    }

    .imgContentWrapper {
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;
        align-items: center;
        margin: 0 0 0 0;

    }

    .aboutImg {
        width: 69%;
        margin: -19px 0 20px -30px;
    }

    .headerWrapper {
        margin-top: 0px;
        /* top: 106px; */
        background-image: url("../../Assets/about-background.jpg");
        background-size: cover;
    }

    .name {
        font-size: 50.5px;
        line-height: 50px;
        margin-top: -411px;
        padding: 19px 0;
    }

    .title {
        width: 71%;
    }

    .imageWrapper {
        /* width: 100%; */
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
        align-items: center;
        margin-left: -200px;
    }

    .about {
        font-size: 9vw;
        line-height: 8vw;
    }

    .name::after {
        font-size: 19px;
        padding: 2px 13px;
        margin-left: 4px;
        margin-top: 7px
    }

    .aboutSection {
        padding: 1rem;
        display: inline-block;
        margin-top: 8px;
        background-image: url("../../Assets/about-content.png");
        background-repeat: no-repeat;
        /* background: rgb(255, 255, 255);
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 7%, rgba(5, 4, 69, 0) 100%, rgba(9, 9, 121, 1) 100%, rgba(0, 212, 255, 1) 100%); */
    }

    .asideStyle {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255 255 255 / 0%) 7%, rgba(5, 4, 69, 0) 100%, rgba(9, 9, 121, 1) 100%, rgba(0, 212, 255, 1) 100%);
        width: 88vw;
    }

    .btnWrapper {
        display: flex;
        justify-content: flex-start;
        margin-left: -1vw;
        width: 38%;
        margin-left: 45px;

    }

    .btn {
        font-size: 2vw;
        padding: 1.5vw 2.5vw;
    }

    .title {
        font-size: 1.9vw;
        line-height: 2vw;
        width: 71%;
        text-align: left;
        margin-left: -15px;

    }

    .aboutSection h2>p:first-of-type {
        margin-top: 10px !important;
        margin-left: 62px;
    }

    .aboutSection h2>p:nth-of-type(2) {
        margin-top: 5px !important;
        margin-left: 62px;
    }


}

@media screen and (min-width:950px) {
    .headerWrapper::after {
        height: 490px;
        right: -151px;
    }

    .name {
        font-size: 4.5vw;
        line-height: 4vw;
        margin-top: -83px;
        width: 50%;
    }

    .title {
        width: 90%;
    }

    .testimoniesTitle {
        text-align: center;
        padding: 0;
    }

    /* .about {
        font-size: 12.5vw;
        line-height: 9vw;
    } */

    .aboutImg {
        width: 31vw;
        margin: -2vw 0 0 20vw;
    }

    .asideStyle {
        width: 58vw;
    }

    .imageWrapper {
        align-items: self-end;
    }

    .swiperDiv {
        margin-left: 0;

        padding-bottom: 40px;
    }

    .missionWrapper {
        width: 115%;
    }
}

@media screen and (min-width:995px) {

    /* black nav-line */
    .headerWrapper::before {
        display: block;
    }

    .btn {
        padding: 10px 50px;
    }

}


@media screen and (min-width:1050px) {

    .headerWrapper::after {
        height: 550px;
        right: -121px;
        background-size: contain;
    }

    .asideStyle {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255 255 255 / 0%) 7%, rgba(5, 4, 69, 0) 100%, rgba(9, 9, 121, 1) 100%, rgba(0, 212, 255, 1) 100%);

    }

}

@media screen and (min-width:1200px) {
    .aboutSection {
        margin-top: 8px;
    }

    /* .about {
        font-size: 160px;
        line-height: 120px;
    } */

    .name {
        font-size: 5vw;
        line-height: 4vw;
        margin-top: -140px;
        width: 37%;

    }

    .imageWrapper {
        margin-left: -389px;
        width: 58%;
    }

    .aboutImg {
        width: 40%;
        margin: -19px 0 0 -49px;
    }

    .headerWrapper::after {
        bottom: -85px;
    }

    .title {
        /* width: 62%; */
    }

}

@media screen and (min-width:1258px) {
    .headerWrapper::after {
        width: 517px;
        height: 720px;
        right: 0;
        background-size: contain;
        background-image: url("../../Assets/case-flowers-bigger.png");
    }

}

@media screen and (min-width:1440px) {

    .btn {
        font-size: 26px;
        padding: 11.5px 26.5px;
    }

    .title {
        font-size: 21.5px;
        line-height: 21px;
    }

    .name {
        font-size: 3.8rem;
        line-height: 3rem;
        margin-top: -33px;
    }

    .about,
    .testimoniesTitle {
        font-size: 160px;
        line-height: 120px;
    }

    .testimoniesTitle {
        padding-top: 50px
    }

    .aboutImg {
        /* width: 50%;
        margin: -19px 0 0 -49px; */
    }

    .imageWrapper {
        padding-right: 50px;
    }

    .asideStyle {
        width: 55rem;
    }

}