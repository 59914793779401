@font-face {
  font-family: 'Fontspring-DEMO-theseasons-bd';
  src: local('Fontspring-DEMO-theseasons-bd'), url(./Fonts/Fontspring-DEMO-theseasons-bd.otf) format('opentype');
}

@font-face {
  font-family: 'Agrandir-GrandHeavy';
  src: local('Agrandir-GrandHeavy'), url(./Fonts/Agrandir-GrandHeavy.otf) format('opentype');
}

@font-face {
  font-family: 'Agrandir-Regular';
  src: local('Agrandir-Regular'), url(./Fonts/Agrandir-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Agrandir-TextBold';
  src: local('Agrandir-TextBold'), url(./Fonts/Agrandir-TextBold.otf) format('opentype');
}

@font-face {
  font-family: 'Swansea-q3pd';
  src: local('Swansea-q3pd'), url(./Fonts/Swansea-q3pd.ttf) format('truetype');
}

@font-face {
  font-family: 'SwanseaBold-D0ox';
  src: local('SwanseaBold-D0ox'), url(./Fonts/SwanseaBold-D0ox.ttf) format('truetype');
}

@font-face {
  font-family: 'TT Interphases Pro Trial Condensed Regular';
  src: local('TT Interphases Pro Trial Condensed Regular'), url(./Fonts/TT\ Interphases\ Pro\ Trial\ Condensed\ Regular.ttf) format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&family=Quattrocento:wght@400;700&display=swap');


:root {

  --gold: #9F7C50;
  --purple: #441564;
  --white: #FFFFFF;
  --off-white: #FDF9EE;
  --brown: #595045;
  --light-grey: #EDF0F2;
  --tan: #E6C18A;

  --title-primary: "Open Sans";
  --title-secondary: "Agrandir-GrandHeavy";
  --body-primary: "TT Interphases Pro Trial Condensed Regular";
  --body-secondary: "Agrandir-Regular";
  --body-secondary-bold: "Agrandir-TextBold";
  --body-tertiary: "Swansea-q3pd";
  --body-tertiary-bold: "SwanseaBold-D0ox";

  --title-about: "Quattrocento";
  --sub-title-about: "Libre Franklin";

}