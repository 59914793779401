.screenDiv {
    position: relative;
    top: -199px;
    z-index: 1
}

@media screen and (min-width: 728px) {
    .screenDiv {
        top: 0
    }

}